import React, { useState,useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { authenticate, logged, getTokenProperty } from './helpers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ROLE_ADMIN } from '../constants/userRoles';
import LoadingOverlayed from '../components/utils/LoadingOverlay'
import { hasPermissionHardc } from './helpers';
import { 
    VIEW_ALBUMS_OF_ME,
    VIEW_CART,
} from './permissions';
import { useDispatch } from 'react-redux';
import { 
    getMyAlbumsAction,
} from '../services/redux/imageRecognitionRedux';
import {
	getBuyablesAction,
} from '../services/redux/cartRedux';
import { 
    getProfileAction,
} from '../services/redux/profileRedux';

const Signin = ({history}) => {
    const dispatch = useDispatch();

    useEffect(() => {        
		dispatch({
            type: 'CLEAR_REDUX',
        });
     }, []);

    //para validar si viene de un redireccionamiento del cambio del email desde el perfil del usuario
    useEffect(() => {        

        if(history.location.state ==='EmailChanged')
        {
            toast.success('You changed your email address. An email has been sent to your Inbox. Follow the instruction to confirm email change.');
        }

     }, [history.location]);
    

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonText, setButtonText] = useState('Log in');
    const [showLoadingOverlayed, setShowLoadingOverlayed] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const clickSubmit = event => {
        event.preventDefault();
        setButtonText('Loging in...');
        setShowLoadingOverlayed(true);
        axios({
            method: 'POST',
            url:`${process.env.REACT_APP_API}/auth/login`,
            data: {email:email.toLowerCase(), password}
        })
        .then(response => {
            //save de response (user,token) localstorage/cookie
            authenticate(response, () => {
                logged() && getTokenProperty('role') === ROLE_ADMIN.description ? history.push('/admin/home') : redirectNotAdmin();
            });  
        })
        .catch(error => {
            setButtonText('Log in');
            setShowLoadingOverlayed(false);
            toast.error(error.response.data.message);
        })
    };

    const redirectNotAdmin = () => {
        dispatch(getProfileAction());
        if(hasPermissionHardc(VIEW_ALBUMS_OF_ME)){
            dispatch(getMyAlbumsAction());
        }
        if(hasPermissionHardc(VIEW_CART)){
            dispatch(getBuyablesAction());
        }
        history.push('/dashboard/home');
    }

    const signinForm = () => (
        <>  
            {/* <div className="row">
                <div className="col-6 text-right">
                    <a href="#" className="btn btn-primary facebook px-3 mb-2"> <span>Login with</span><i className="bi bi-facebook ml-2"></i> </a>
                </div>
                <div className="col-6 text-left">
                    <a href="#" className="btn btn-white facebook px-3 mb-2"> <span>Login with</span><i className="bi bi-google ml-2"></i> </a>
                </div>
            </div>						
            <h6 className="mt-2 mb-3"><span>or</span></h6> */}
            {/* <p>Create an account</p> */}
            <div className="form-group mt-3">
                <input onChange={e => setEmail( e.target.value)} value={email} required type="text" className="form-control form-control-lg" id="email" aria-describedby="emailHelp" placeholder="email@domain.com" />
            </div>
            <div className="form-group mt-4">	
                <div className="input-group" id="show_hide_password">
                    <input onChange={e => setPassword( e.target.value)} value={password} required className="form-control form-control-lg" type={showPassword ? "text":"password"} id="password" placeholder="password" />
                    <div className="input-group-append show-password">
                        <span onClick={e => setShowPassword(!showPassword)}><i className={!showPassword ? "bi bi-eye-slash" : "bi bi-eye"} ></i></span>
                    </div>
                </div>
                <label className="float-right"><Link className="font-14 font-weight-light" to="/password/forgot">Forgot password?</Link></label>
            </div>
            <button onClick={clickSubmit} type="submit" className="btn btn-primary btn-lg btn-block my-4 float-right rounded-pill">{buttonText}</button>
            <p>Don’t have an account ? <Link to="/register"> Create one now </Link></p>
            <p>Need a validation link ? <Link to="/account/resend-validation-link"> Request Validation Link</Link></p>
        </>
    )

    return (
        <>
            <ToastContainer 
                position="bottom-right"
            />

            {logged() ? <Redirect to="/" /> : null}

            {showLoadingOverlayed &&
                <LoadingOverlayed/>
            }

            <main role="main" className="flex-shrink-0">
                <section className="bg-login">
                    <div className="container mt-md-5 pt-md-5 pb-5">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 text-center">
                                <img className="mb-0" src="/assets/img/logo-color.svg" alt="" width="300" height="150" />                                    
                                <form>
                                    {signinForm()}   
                                </form>
                            </div>
                        </div>
                    </div>

                </section>
            </main>

        </>
    )    
}

export default Signin;
