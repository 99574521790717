import React, { lazy, Suspense } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import { DASHBOARD_ROUTE } from './routes/dashboardRoutes';
import { ADMIN_ROUTE } from './routes/adminRoutes';
import Fallback from './components/utils/Fallback';
import ErrorBoundary from './ErrorBoundary';
import App from './App';
import Signup from './auth/Signup';
import Signin from './auth/Signin';
import Forgot from './auth/Forgot';
import Activate from './auth/Activate';
import ConfirmPurchase from './components/purchases/ConfirmPurchase';
import Reset from './auth/Reset';
import ValideEmail from './auth/ValidateEmail';
import PrivateRoute from './auth/PrivateRoute';
import AdminRoute from './auth/AdminRoute';
import BaseRedirection from './containers/BaseRedirection';
import PageNotFound from './containers/PageNotFound';
import About from './components/landing/About';
import Photographers from './components/landing/Photographers';
import Users from './components/landing/Users';
import TermsOfService from './components/landing/TermsOfService';
import PrivacyPolicy from './components/landing/PrivacyPolicy';
import ResendValidationLink from './auth/ResendValidationLink';

const Dashboard = lazy(() => import('./containers/dashboard/Dashboard'));
const Admin = lazy(() => import('./containers/admin/Admin'));

const Routes = () => {
    return (
        <Suspense fallback={ <Fallback />} >
            <ErrorBoundary>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={App} />
                        <Route path="/register" exact component={Signup} />
                        <Route path="/login" exact component={Signin} />
                        <Route path="/password/forgot" exact component={Forgot} />
                        <Route path="/account/activate/:token" exact component={Activate} />
                        <Route path="/confirmPurchase/:token" exact component={ConfirmPurchase} />
                        <Route path="/account/change-password/:token" exact component={Reset} />
                        <Route path="/account/resend-validation-link" exact component={ResendValidationLink} />
                        <Route path="/account/validate-email/:token" exact component={ValideEmail} />
                        <Route path="/about" exact component={About} />
                        <Route path="/photographers" exact component={Photographers} />
                        <Route path="/users" exact component={Users} />
                        <Route path="/termsOfService" exact component={TermsOfService} />
                        <Route path="/privacyPolicy" exact component={PrivacyPolicy} />
                        <Route path="/baseRedirection" exact component={BaseRedirection} />

                        <PrivateRoute path={DASHBOARD_ROUTE.DASHBOARD} component={Dashboard} />
                        <AdminRoute path={ADMIN_ROUTE.ADMIN} component={Admin} />
                        <Route exact component={PageNotFound} />

                    </Switch>
                </BrowserRouter>
            </ErrorBoundary>
        </Suspense>
    );
};

export default Routes;