import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import profileRedux from './profileRedux'
import imageRecognitionRedux from './imageRecognitionRedux'
import cartRedux from './cartRedux'
 
const appReducer = combineReducers({
	profile: profileRedux,
	imageRecognition: imageRecognitionRedux,
	cart: cartRedux
})

const rootReducer = (state, action) => {
	if (action.type === 'CLEAR_REDUX') {
	  return appReducer(undefined, action)
	}
  
	return appReducer(state, action)
  }
 
export default function generateStore() {
	const store = createStore( rootReducer, composeWithDevTools( applyMiddleware(thunk) ) )
	return store
}