import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';

const About = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [questions, setQuestions] = useState('');

    const clickSubmit = () =>{

    }

    return(
        <>
            {/* TODO: Create container to avoid using header and footer in every screen */}
            <Header
                relativeHeader
            />
                <div className="container-fluid px-0" >
                    <div style={{overflow: "hidden", height:'100%'}}>
                        <div>
                            <div className="wrapper row">            
                                <nav id="sidebarMenu" className="col-md-3 col-lg-2 bg-light sidebar" style={{position:'relative', paddingLeft:'10px', paddingTop:'0px'}}>
                                    <div className="sidebar-sticky pt-3">
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link" href="#about">
                                                    About Findpic
                                                </a>
                                            </li>
                                            {/* <li className="nav-item">
                                                <a className="nav-link" href="#faq">
                                                    FAQ
                                                </a>
                                            </li> */}
                                            <li className="nav-item">
                                                <a className="nav-link" href="#contact">
                                                    Contact
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 h-100 " style={{marginTop:'30px'}}>
                                    <div className="container-fluid">      	
                                        <div className="content-panel">
                                            <div className="content-header-wrapper mb-4" >
                                                <section id="about" data-spy="about" data-target="#about">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    About Findpic
                                                                </h2>
                                                                <p>Findpic is a platform for photographers by photographers. We wanted to help you solve the most asked question in the photography business: “Where are you posting the photos?”</p>
                                                                <p>At Findpic, we know that most pictures never reach the photographed people. We are here to solve this!  </p>
                                                                <p>As photographers, one of the most fulfilling parts of our job is the happiness we bring to our clients when they get those special moments captured for eternity. For all these reasons, we put together the latest AI technology. The goal is to make it fast and simple for your clients to reach their photos, purchase, and download them. While making sure that as a freelancer, you receive your payments directly from your clients, no middle man! </p>
                                                                <p>At Findpic we strive to provide you with the right tools to have a successful career in doing what you love!</p>
                                                                <p>As the clients, you will finally be able to find your photos easily, without having to chase the photographer, or without having to do too much browsing. Using advanced AI technology you can find your photos with a click of a button, pay the photographer directly and enjoy a high-quality digital image. </p>
                                                                <p>At Findpic we know the client comes first, therefore we want you to get your photos as easy and fast as possible!</p>
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                {/* <section id="faq" data-spy="faq" data-target="#faq">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    FAQ
                                                                </h2>
                                                                <p>
                                                                    Coming soon...
                                                                </p>
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> */}
                                                <section id="contact" data-spy="contact" data-target="#contact">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    Contact
                                                                </h2>
                                                                <p>
                                                                    Should you have any question, you can email us at <a href="mailto: contact@findpic.co">contact@findpic.co</a>, we'll be happy to help.
                                                                </p>
                                                                {/* <form>
                                                                    <div className="row">
                                                                        <div className="form-group mb-0 col-md-6 col-12">
                                                                            <label className="myLabel">Name</label>
                                                                            <input type="text" onChange={e => setName(e.target.value)} value={name} className="form-control form-control-lg" placeholder="Eg. John windsurfing" />
                                                                        </div>
                                                                        <div className="form-group mb-0 col-md-6 col-12">
                                                                            <label className="myLabel">Email</label>
                                                                            <input type="text" onChange={e => setEmail(e.target.value)} value={email} className="form-control form-control-lg" placeholder="John.windsurfing@gmail.com" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group mb-0">
                                                                        <label className="myLabel">Questions</label>
                                                                        <textarea type="text" onChange={e => setQuestions(e.target.value)} value={questions} className="form-control form-control-lg"/>
                                                                    </div>
                                                                    <button onClick={clickSubmit} type="button" className="btn btn-primary mt-5 mb-5 float-right" data-dismiss="modal">Send</button>
                                                                </form> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>                
            <Footer/>
        </>
    )
}

export default About;