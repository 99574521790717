import React, { Component } from 'react'

class Loading extends Component {
	
	render() { 
        return(
			<div style={{minHeight:'500px',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}} >
				<div className="rainbow">
					<div className="circle1"></div>
					<div className="dot1"></div>
					<div className="dot2"></div>
				</div>
			</div>
        );
	}
}
 
export default Loading;