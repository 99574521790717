import React from 'react'
import MasonryReact, {ResponsiveMasonry} from "react-responsive-masonry"

const Masonry = (props) => {
	const {
		responsive,
		columnscount,
	} = props

	return (
		responsive ? 
			<ResponsiveMasonry
				columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
			>
				<MasonryReact>
					{props.children}
				</MasonryReact>
			</ResponsiveMasonry>
		:
			<MasonryReact columnsCount={columnscount ? columnscount : null }>
				{props.children}
			</MasonryReact>
	);
}

export default Masonry
