import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { httpApiPost } from '../../services/helpers/axiosPrivate';

const ConfirmPurchase = ({match, history}) => {
    const [token, setToken] = useState('');
    const [confirmationSuccess, setConfirmationSuccess] = useState(false);

    useEffect(() => {
        let token = match.params.token
        if(token) {
            setToken(token);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const clickSubmit = async() => {
        try {
            const response = httpApiPost('confirmPurchase',{token})
            if(response){
                toast.success('Purchase confirmed!')
                setConfirmationSuccess(true);
            }
        } catch (error) {
            toast.error(error.message);       
        }
    };  

    return (
        <>          
        
            <ToastContainer 
                position="bottom-right"
            />          
            
            <main role="main" className="flex-shrink-0">
                <section className="bg-login">
                    <div className="container mt-md-5 pt-md-5 pb-5">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 text-center">
                                <img className="mb-0" src="/assets/img/logo-color.svg" alt="" width="300" height="150" />
                                <div className="row">
                                    <h1 className="p-1 text-center">Are you sure that you want to confirm the purchase?</h1>                                 
                                    <button onClick={clickSubmit} disabled={confirmationSuccess} type="button" className="btn btn-primary btn-lg btn-block my-4 float-right rounded-pill">Confirm</button>
                                </div>
                                <p className="text-center">Go to<Link to="/login"> Log In</Link></p>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )    
}

export default ConfirmPurchase;