import React from 'react';
import Header from './components/landing/Header';
import Footer from './components/landing/Footer';
import Home from './components/landing/Home';

const App = () => {
	return (
		<>
			<Header />
			<Home />      
			<Footer />
		</>    
	);
};

export default App;

