import React from 'react'
import { 
	Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PicturesContainerMasonry from '../utils/PicturesContainerMasonry';

const ModalFoundPhotos = (props) => {
	const {
		showModalFoundPhotos,
		closeModal,
      myPictures,
	} = props

	return (
         <Modal show={showModalFoundPhotos} onHide={closeModal} size="lg" scrollable={true} keyboard={false} >
			<Modal.Header closeButton > {/* className="photobooth-header" */}
         </Modal.Header>
			<Modal.Body>
			<PicturesContainerMasonry pictures={myPictures} />
         </Modal.Body>
         <Modal.Footer as={'col-12'}>
         <div className="row p-3" >{/* className="row p-3" */}
               <div className="col-12">{/* className="col-12" */}
                  <h3 className="mb-3">Hi,</h3>
                  <div>
                     <p>We found {myPictures && myPictures.length} photos of you!</p>
                     <p>To see more pictures of you we invite you to:</p>
                  </div>
               </div></div>
         </Modal.Footer>
			<Modal.Footer>
            <Link to={'/login'} className="btn btn-secondary mt-4 mr-3">Log In</Link>
            <Link to={'/register'} className="btn btn-primary mt-4 mr-3">Register</Link>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalFoundPhotos;
