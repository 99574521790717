import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Photographers = () => {

    return(
        <>
            {/* TODO: Create container to avoid using header and footer in every screen */}
            <Header
                relativeHeader
            />
                <div className="container-fluid px-0" >
                    <div style={{overflow: "hidden", height:'100%'}}>
                        <div>
                            <div className="wrapper row">            
                                <nav id="sidebarMenu" className="col-md-3 col-lg-2 bg-light sidebar" style={{position:'relative', paddingLeft:'10px', paddingTop:'0px'}}>
                                    <div className="sidebar-sticky pt-3">
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link" href="#how">
                                                    How does it Work?
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#pricing">
                                                    Pricing
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#copyright">
                                                    Copyright
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 h-100 " style={{marginTop:'30px'}}>
                                    <div className="container-fluid">      	
                                        <div className="content-panel">
                                            <div className="content-header-wrapper mb-4" >
                                                <section id="how" data-spy="how" data-target="#how">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    How does it Work?
                                                                </h2>
                                                                <p>Register and set up your preferred payment methods to receive money directly from your clients. </p>
                                                                <p>Go out and enjoy shooting your favorite activities. When people ask you: “Where are you going to post the photos?”, You can simply reply: “Find-pic-dot-co.”</p>
                                                                <p>Upload your photos to Findpic and wait for the orders to come. Once you make a sale, you will receive a confirmation email affirming your customers made a payment through one of your previously selected payment methods. Click confirm and that's it! The images will be available for your customers in their Findpic account,  ready to download in one click!</p>
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section id="pricing" data-spy="pricing" data-target="#pricing">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    Pricing
                                                                </h2>
                                                                <p>How much you want to charge for photos is totally up to you! Choose your preferred currency, and set the price per photo as you wish. Findpic takes a 20% commission on your sales.</p>
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section id="copyright" data-spy="copyright" data-target="#copyright">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    Copyright
                                                                </h2>
                                                                <p>
                                                                You as the photographer are the copyright owner of all the pictures that you upload to Findpic. Using Findpic, you are selling your customers the licensing rights to use your photos for personal or commercial use in digital or print form. 
                                                                </p>
                                                                {/* <p>
                                                                    The photographer has the copyright to the photos. The client will buy a license to the photo, but not the copyrights.
                                                                </p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>                
            <Footer/>
        </>
    )
}

export default Photographers;