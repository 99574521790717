import axios from 'axios';
import { API_URL } from '../config';
import { createBrowserHistory } from 'history';
import  qs  from 'qs';
import { getToken, signout } from '../../auth/helpers';

const history = createBrowserHistory();

function httpApiGet(url, data){
    return (
        axios.get(API_URL + '/' + url, {
            params: data ? data : null,
            headers: {Authorization: `Bearer ${getToken()}`},
            paramsSerializer: function(data) {
                return qs.stringify(data, {arrayFormat: 'repeat'})
            }
        }).then(response => {
            if(response.status === 200) {
                if(response.data.data){
                    return response.data.data;
                }
                return response.data;
            }
        })
        .catch((request => {
            if(request?.response?.status === 401){
                signout( () => {
                    history.push('/');
                    window.location.reload();
                });  
                return null;
            }
            if(request.response) {
                const status = request.response?.status || 998;
                const message = request.response?.data?.message || `Oops! Something went wrong (code error: ${status})`;
                throw new ApiError(message, status);
            } 
            else {
                throw new ApiError('Oops! Something went wrong (code error: 999)', 999);
            }
        }))
    );
}

function httpApiGetFile(url, data){

    return (
        axios.get(API_URL + '/' + url, {
            params: data ? data : null,
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
            responseType: "blob"
        }).then(response => {
            if(response.status === 200) {
                if(response.data.data){
                    return response.data.data;
                }
                return response.data;
            }
        })
        .catch((request => {
            if(request?.response?.status === 401){
                signout( () => {
                    history.push('/');
                    window.location.reload();
                });  
                return null;
            }
            if(request.response) {
                const status = request.response?.status || 998;
                const message = request.response?.data?.message || `Oops! Something went wrong (code error: ${status})`;
                throw new ApiError(message, status);
            } 
            else {
                throw new ApiError('Oops! Something went wrong (code error: 999)', 999);
            }
        }))
    );
}

function httpApiGetPaginated(url, data){
    
    return (
        axios.get(API_URL + '/' + url, {
            params: data ? data : null,
            headers: {Authorization: `Bearer ${getToken()}`},
            paramsSerializer: function(data) {
                return qs.stringify(data, {arrayFormat: 'repeat'})
            }
        }).then(response => {
            if(response.status === 200) {
                return response.data;
            }
        })
        .catch((request => {
            if(request?.response?.status === 401){
                signout( () => {
                    history.push('/');
                    window.location.reload();
                });  
                return null;
            }
            if(request.response) {
                const status = request.response?.status || 998;
                const message = request.response?.data?.message || `Oops! Something went wrong (code error: ${status})`;
                throw new ApiError(message, status);
            } 
            else {
                throw new ApiError('Oops! Something went wrong (code error: 999)', 999);
            }
        }))
    );
}

function httpApiPost(url, data = {}){
    return (
        axios.post(API_URL + '/' + url, data ,{
            headers: {Authorization: `Bearer ${getToken()}`},
            // timeout: 15000,
        }).then(response => {
            return response.data;
        })
        .catch((request => {
            if(request?.response?.status === 401){
                signout( () => {
                    history.push('/');
                    window.location.reload();
                });  
                return null;
            }
            if(request.response) {
                const status = request.response?.status || 998;
                const message = request.response?.data?.message || `Oops! Something went wrong (code error: ${status})`;
                throw new ApiError(message, status);
            } 
            else {
                throw new ApiError('Oops! Something went wrong (code error: 999)', 999);
            }
        }))
    );
}

function httpApiUploadFile(url, file){

    return (
        axios.post(API_URL + '/' + url, file,{
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            if(response.status === 200 ||response.status === 201) {
                return response.data;
            } 
        })
        .catch((request => {
            if(request?.response?.status === 401){
                signout( () => {
                    history.push('/');
                    window.location.reload();
                });  
                return null;
            }
            if(request.response) {
                const status = request.response?.status || 998;
                const message = request.response?.data?.message || `Oops! Something went wrong (code error: ${status})`;
                throw new ApiError(message, status);
            } 
            else {
                throw new ApiError('Oops! Something went wrong (code error: 999)', 999);
            }
        }))
    );
}

function httpApiDelete(url, data){
    return (
        axios.delete(API_URL + '/' + url,{
            data, 
            headers: {Authorization: `Bearer ${getToken()}`}
        }).then(response => {
            if(response.status === 200) {
                return response.data;
            }
        })
        .catch((request => {
            if(request?.response?.status === 401){
                signout( () => {
                    history.push('/');
                    window.location.reload();
                });  
                return null;
            }
            if(request.response) {
                const status = request.response?.status || 998;
                const message = request.response?.data?.message || `Oops! Something went wrong (code error: ${status})`;
                throw new ApiError(message, status);
            } 
            else {
                throw new ApiError('Oops! Something went wrong (code error: 999)', 999);
            }
        }))
    );
}

class ApiError extends Error {
    constructor(message, status) {
        super(message);
        this.status = status;
    }
}
    
export {
    httpApiGet,
    httpApiGetPaginated,
    httpApiPost,
    httpApiDelete,
    httpApiUploadFile,
    httpApiGetFile,
}