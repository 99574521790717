/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useCallback, useEffect} from "react";
import Webcam from "react-webcam";

const defaultVideoConstraints = {
	width: 1280,
	height: 720,
	facingMode: "user"
  };

  
  const Camera = (props) => {
	  const {
		outerTakePicture,
		setOuterTakePicture,
		webcamClassname,
		pictureClassname,
		videoConstraints,
		screenshotFormat,
		getPictureParent,
		actionPictureParent,
	} = props
	const webcamRef = useRef(null);
	const [imgSrc, setImgSrc] = useState(null);
  
	const urltoFile = (url, filename, mimeType) => {
		return (fetch(url)
			.then(function(res){return res.arrayBuffer();})
			.then(function(buf){return new File([buf], filename,{type:mimeType});})
		);
	}

	const capture = useCallback(
		async() => {
		const imageSrc = webcamRef.current.getScreenshot();
		if(imageSrc){
			const file = await urltoFile(imageSrc,'screenshot',screenshotFormat ? screenshotFormat : "image/jpeg");
			setImgSrc(imageSrc);
			if(getPictureParent){
				getPictureParent(file);
			}
		}
	  },
	  [webcamRef]
	);

	useEffect(() => {
		if(props.outerTakePicture){
			capture();
			setOuterTakePicture(false);
		}
    }, [outerTakePicture]) 
  
	return (
	  <>
		<Webcam
			className={webcamClassname ? webcamClassname : 'defaultWebcamClassname'}
			audio={false}
			ref={webcamRef}
			screenshotFormat={screenshotFormat ? screenshotFormat : "image/jpeg"}
			videoConstraints={videoConstraints ? videoConstraints : defaultVideoConstraints}
		/>
		<button className="reset-shot" onClick={e=> setImgSrc(null)}><i className="bi bi-arrow-clockwise"></i></button>
		<button className="take-shot" type="button" onClick={capture}  />
		{imgSrc && 
			<button onClick={actionPictureParent} className="search-findpic"><i className="bi bi-check2"></i></button>
		}
		{imgSrc && (
			<img
				className={pictureClassname ? pictureClassname : 'defaultPictureClassname'}
				src={imgSrc} alt="img"
			/>
		)}
	  </>
	);
};

export default Camera;