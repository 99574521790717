import React from 'react';
import {Redirect} from 'react-router-dom';
import { hasPermissionHardc, logged,signout } from '../auth/helpers';
import { 
    VIEW_HOME_CONSUMER,
    VIEW_HOME_PHOTOGRAPHER,
    VIEW_HOME_ADMIN,
 } from '../auth/permissions';

const BaseRedirection = () => {
    if(!logged()){
        return <Redirect to={'/login'}/>
    }else if(hasPermissionHardc(VIEW_HOME_CONSUMER) || hasPermissionHardc(VIEW_HOME_PHOTOGRAPHER) ){
        return <Redirect to={'/dashboard/home'}/>
    }
    else if(hasPermissionHardc(VIEW_HOME_ADMIN)){
        return <Redirect to="/admin/home"/>
    }
    else{
        signout();
        return <Redirect to="/login"/>
    }
};

export default BaseRedirection;