import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Users = () => {

    return(
        <>
            {/* TODO: Create container to avoid using header and footer in every screen */}
            <Header
                relativeHeader
            />
                <div className="container-fluid px-0" >
                    <div style={{overflow: "hidden", height:'100%'}}>
                        <div>
                            <div className="wrapper row">            
                                <nav id="sidebarMenu" className="col-md-3 col-lg-2 bg-light sidebar" style={{position:'relative', paddingLeft:'10px', paddingTop:'0px'}}>
                                    <div className="sidebar-sticky pt-3">
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link" href="#how">
                                                    How does it Work?
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#pricing">
                                                    Pricing
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#copyright">
                                                    Copyright
                                                </a>
                                            </li>
                                            {/* <li className="nav-item">
                                                <a className="nav-link" href="#privacy">
                                                    Privacy
                                                </a>
                                            </li> */}
                                            {/* <li className="nav-item">
                                                <a className="nav-link" href="#register">
                                                    Register
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </nav>
                                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 h-100 " style={{marginTop:'30px'}}>
                                    <div className="container-fluid">      	
                                        <div className="content-panel">
                                            <div className="content-header-wrapper mb-4" >
                                                <section id="how" data-spy="how" data-target="#how">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    How does it Work?
                                                                </h2>
                                                                <p>Registration is fast, simple, and completely free!</p>
                                                                <p>After registering, you can see if you appear in any of the albums on Findpic in the “Pictures of me” tab of your dashboard. You can choose to browse the entire album or see only the photos you appear in and select which ones you would like to purchase.</p>
                                                                <p>In the cart section, you may choose from any of the photographer’s payment options.</p>
                                                                <p>To make payments via second party payment apps like Bit or PayBox, please add and confirm your phone number in your profile. Once you click “pay”, a message with the phone number and the total payment will appear. At this point, please send the photographer the corresponding payment through the selected payment method. Once the payment is received by the photographer, they will confirm the payment and the photos will be available for you to download. You can find the photos in the “My Pictures” section of your Findpic dashboard. Please download and keep a copy of the images in a safe place.</p>
                                                                {/* <p>
                                                                    Take a Selfie in our special camera to find out if any photos of you exist on the website. 
                                                                    If there are photos of you, you will need to register to see them. Don't worry!! registering takes less than a minute and it is completely free!!!
                                                                </p>
                                                                <p>
                                                                    Your dashboard contains your chosen payment method (for buying your pictures in high resolution with no watermark), and some useful shortcuts. 
                                                                </p>
                                                                <p>
                                                                    Go to “My profiles” to create your user profile. You have the option of connecting your account to social media, upload more photos of yourself so the AI can learn to recognize your face better, and choose your notification settings.
                                                                </p>
                                                                <p>
                                                                    In “Pictures of me” you will be able to see all the pictures you appear in, uploaded by the photographers.
                                                                </p>
                                                                <p>
                                                                    The “Cart" maintains all the pictures you chose to buy, while in “My pictures” you will find pictures you purchased in the past.
                                                                </p> */}
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section id="pricing" data-spy="pricing" data-target="#pricing">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    Pricing
                                                                </h2>
                                                                <p>
                                                                The price of a photo is set by the photographer, in his preferred currency. You may choose your preferred payment method in your cart before checkout.
                                                                </p>
                                                                {/* <p>
                                                                    The price of a photo is chosen by the photographer, in his preferred currency. 
                                                                    <br />
                                                                    You may choose your payment method in your dashboard.
                                                                </p>
                                                                <p>
                                                                    Your payment details are secured. 
                                                                </p> */}
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section id="copyright" data-spy="copyright" data-target="#copyright">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    Copyright
                                                                </h2>
                                                                <p>
                                                                Copyrights of the images belong to the photographers. Purchases of photos through Findpic provide you with personal licensing rights to use those images. All photos are for private use and to be used only in private social media or personal printing, and cannot be passed to any commercial body for any further use. Photo prices are for the digital version of the image intended for personal use only. For commercial use (companies, brands, organizations, etc.) please email info@findpic.co
                                                                </p>
                                                                {/* <p>
                                                                    Copyright of the photo belongs to the photographer. You may buy the license to use it or print it. 
                                                                </p> */}
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                {/* <section id="privacy" data-spy="privacy" data-target="#privacy">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    Privacy
                                                                </h2>
                                                                <p>
                                                                    Coming soon...
                                                                </p>
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> */}
                                                {/* <section id="register" data-spy="register" data-target="#register">
                                                    <div className="panel">
                                                        <div className="container">
                                                            <div className="row-fluid">
                                                                <h2 className="title">
                                                                    Register
                                                                </h2>
                                                                <p>
                                                                    Coming soon...
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> */}
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>                
            <Footer/>
        </>
    )
}

export default Users;