import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import PasswordStrengthBar from 'react-password-strength-bar';
import { getSignUpUserType, switchSignUpUserType } from '../constants/userRoles';
import {useLocation} from 'react-router-dom';
import { httpApiPost } from '../services/helpers/axiosPrivate';
import LoadingOverlayed from '../components/utils/LoadingOverlay'
import { useSelector } from 'react-redux';
import { 
	Form,
} from 'react-bootstrap';
import TermsOfServiceText from '../components/common/TermsOfServiceText';
import ModalTermsOfService from '../components/landing/ModalTermsOfService';

const Signup = () => {
    const checkbox = useRef(null);
    const queryParamManager =  new URLSearchParams(useLocation().search);
    const [name, setName] =  useState('');
    const [surname, setSurname] =  useState('');
    const [email, setEmail] =  useState('');
    const [validated, setValidated] = useState(false);
    const [password, setPassword] =  useState('');
    const [password2, setPassword2] =  useState('');
    const [showPassword, setShowPassword] =  useState(false);
    const [showPassword2, setShowPassword2] =  useState(false);
    const [buttonText, setButtonText] =  useState('Create account');
    const [userType, setUserType] = useState(null);
    const [switchUserType, setSwitchUserType] = useState(null);
    const [checked, setChecked] = useState(false);
    const [showLoadingOverlayed, setShowLoadingOverlayed] = useState(false);
    const [showModalTermsOfService, setShowModalTermsOfService] = useState(false);
    const imageRecognitionPicture = useSelector(store => store.imageRecognition.landingRecognitionPicture);

    useEffect(() => {
        getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(checkbox.current){
            checkbox.current.checked = checked
        }
    }, [checked]);

    const getUserType = () => {
        const userTypeAux = getSignUpUserType(queryParamManager.get('userType'));
        setUserType(userTypeAux);
        setSwitchUserType(switchSignUpUserType(userTypeAux.id));
    }

    // const switchUserTypeAction = () => {
    //     const userTypeAux = switchSignUpUserType(userType.id);
    //     setSwitchUserType(userType);
    //     setUserType(userTypeAux);
    // }

    const resetform = (accountCreated) => {
        setName('');
        setSurname('');
        setEmail('');
        setPassword('');
        setPassword2('');
        if(accountCreated){
            setButtonText('Account created');
        }else{
            setButtonText('Create account');
        }
        setChecked(false);
        setShowPassword(false);
        setShowPassword2(false);
        setValidated(false);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity()) {
            submitChanges();
        }
    };

    const submitChanges = async () => {
        if (password !== password2){
            toast.error("Passwords don't match."); 
        }
        else {
            if(checked){
                setButtonText('Creating account...');
                setShowLoadingOverlayed(true);
                try {
                    const formData = new FormData();
                    formData.append("name", name);
                    formData.append("surname", surname);
                    formData.append("email", email.toLowerCase());
                    formData.append("password", password);
                    formData.append("role", userType.id);
                    formData.append("imageRecognitionPicture", imageRecognitionPicture);

                    const response = await httpApiPost('auth/register', formData);
                    if(response){
                        resetform(true);
                        toast.success('An email has been sent to your Inbox. Follow the instruction to activate your account.');
                    }
                } catch (error) {
                    setButtonText('Create account');
                    toast.error(error.message);
                }finally{
                    setShowLoadingOverlayed(false);
                }
            } else {
                toast.error('Terms of service are required.');
            }
        }
    };

    const signupForm = () => (
        <>
            <div className="form-group mt-0">
                <input onChange={e => setName(e.target.value)} required  value={name} type="text" className="form-control form-control-lg" id="name" placeholder="Name"/>
            </div>
            <div className="form-group mt-0">
                <input onChange={e => setSurname(e.target.value)} required value={surname} type="text" className="form-control form-control-lg" id="surname" placeholder="Surname"/>
            </div>
            <div className="form-group mt-3">
                <input onChange={e => setEmail(e.target.value)} required value={email} type="text" className="form-control form-control-lg" id="email" placeholder="E-mail address"/>
            </div>

            <div className="form-group mt-3">	
                <div className="input-group" id="show_hide_password" style={{'width':'99%'}}>
                    <input onChange={e => setPassword(e.target.value)} required value={password} style={{backgroundImage:'none'}} className="form-control form-control-lg" id="password" type={showPassword ? "text":"password"}  placeholder="password"/>
                    <div className="input-group-append show-password">
                        <span onClick={e => setShowPassword(!showPassword)}><i className={!showPassword ? "bi bi-eye-slash" : "bi bi-eye"} ></i></span>
                    </div>
                    
                </div>                
            </div>
            
            <div className="form-group mt-3">	
                <div className="input-group" id="show_hide_password" style={{'width':'99%'}}>
                    <input onChange={e => setPassword2(e.target.value)} required value={password2} style={{backgroundImage:'none'}} className="form-control form-control-lg" type={showPassword2 ? "text":"password"} placeholder="repeat password" />
                    <div className="input-group-append show-password">
                        <span onClick={e => setShowPassword2(!showPassword2)}><i className={!showPassword2 ? "bi bi-eye-slash" : "bi bi-eye"} ></i></span>
                    </div>
                </div>
            </div>

            <PasswordStrengthBar 
                password={password}
                minLength="8" 
                minScore="3"
            />

            <div className="text-left pt-2" >
                <div className="custom-control custom-checkbox" style={{width:'100%'}}>
                    <input 
                        type="checkbox" 
                        className="custom-control-input" 
                        id="customControlAutosizing" 
                        defaultChecked={checked} 
                        ref={checkbox}
                        onChange={() => setChecked(!checked)}
                    />
                    <label className="custom-control-label font-14" htmlFor="customControlAutosizing">I agree to the <a href="#" data-toggle="modal" data-target="#tyc" onClick={() => setShowModalTermsOfService(true)}>terms of service</a></label>
                </div>
            </div>
            <button  type="submit" className="btn btn-primary btn-lg btn-block my-4 float-right rounded-pill">{buttonText}</button>
            <p>Already have an account ? <Link to="/login"> Log In</Link></p>
            <p>Need a validation link ? <Link to="/account/resend-validation-link"> Request Validation Link</Link></p>
        </>
    )

    return (
        <>
            <ToastContainer 
                position="bottom-right"
            />
            {showLoadingOverlayed &&
                <LoadingOverlayed/>
            }

            <main role="main" className="flex-shrink-0">
            <ModalTermsOfService
            showModalTermsOfService={showModalTermsOfService}
            closeModal={() => setShowModalTermsOfService(false)}
            acceptTerms={() => {
                setShowModalTermsOfService(false);
                setChecked(true);
            }}
            />
                <section className="bg-login">
                    <div className="container pt-md-5 pb-5">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 col-12 text-center">
                                <img className="mb-0" src="/assets/img/logo-color.svg" alt="" width="300" height="150" />
                                <h2 className="">{userType?.descriptionUser === 'Photographer' && userType.descriptionUser + ' Registration'}</h2>
                                {/* <h6 className="">Switch to <span style={{textDecoration: 'underline',cursor:'pointer'}} onClick={e => switchUserTypeAction()}>{switchUserType && switchUserType.descriptionUser}</span></h6> */}
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                   {signupForm()} 
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )    
}

export default Signup;
