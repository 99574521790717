import React from 'react'
import Masonry from './Masonry'
import Loading from './Loading'

const PicturesContainerMasonry = (props) => {
	const {
		pictures,
		pictureContent,
	} = props

	return (
		<div className="row">
			<div className="col-12 text-center w-100">
				<div className="grid text-center">
					{ !pictures ? 
						<Loading/>
					:
						<Masonry
							responsive
						>	
							{pictures.map(picture =>(
								<div key={picture.photo.id} className="mb-2 ml-2 mr-2 grid-item water">
									<div className="portfolio-wrapper">
										<div className="portfolio-image">
											<img src={picture.photo.watermarkUrl} alt=""/>
										</div>
										{pictureContent && 
											<div className="portfolio-overlay">
												<div className="portfolio-content">
													{pictureContent(picture)}
												</div>
											</div>
										}
									</div>
								</div>
							))}
						</Masonry>
					}
				</div>
			</div>
		</div>
	)
}

export default PicturesContainerMasonry
