import React from 'react';
import Header from './Header';
import Footer from './Footer';
import TermsOfServiceText from '../common/TermsOfServiceText';

const TermsOfService = () => {

    return (
        <>
            {/* TODO: Create container to avoid using header and footer in every screen */}
            <Header
                relativeHeader
            />
            <div className="container-fluid px-0" >
                <div style={{ overflow: "hidden", height: '100%' }}>
                    <div className="row ml-5">
                        <div className="col-md-12" style={{ marginTop: '30px', minHeight:'500px' }}>
                            <h2 className="title">
                                Terms of service
                            </h2>
                            <TermsOfServiceText/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TermsOfService;