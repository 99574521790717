const API_URL =  process.env.REACT_APP_API;
const CHUNK_SIZE_KB =  process.env.REACT_APP_CHUNK_SIZE_KB;
const MAX_PARALLEL_UPLOADS =  process.env.REACT_APP_MAX_PARALLEL_UPLOADS;
const TIMEOUT = 5000; // time in ms
const ERROR_MESSAGE = 'Server error. Please try again';

export {
    API_URL,
    CHUNK_SIZE_KB,
    MAX_PARALLEL_UPLOADS,
    TIMEOUT,
    ERROR_MESSAGE,
};