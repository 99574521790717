export const DASHBOARD_ROUTE = {
    DASHBOARD: "/dashboard",
    DASHBOARD_HOME: "/home",
    DASHBOARD_PROFILE: "/profile",
    DASHBOARD_ALBUMS: "/albums",
    DASHBOARD_ALBUM: "/albums/:id/:name",
    DASHBOARD_ORDERS: "/orders",
    DASHBOARD_ORDERS_CONSUMER: "/myOrders",
    DASHBOARD_CART: "/cart",
    DASHBOARD_CHECKOUT: "/checkout",
    DASHBOARD_ALBUMS_OF_ME: "/albumsOfMe",
    DASHBOARD_PICTURES_OF_ME: "/picturesOfMe/:id?",
    DASHBOARD_PURCHASED_PICTURES: "/purchasedPictures",
    DASHBOARD_PURCHASED_PICTURE: "/purchasedPictures/:id",
    DASHBOARD_PICTURE_DETAIL: "/pictureDetail/:id",
};