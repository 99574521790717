import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


const Forgot = ({history}) => {

    //para manejar el state del registro
    const [values, setValues] = useState({               
        email:'',       
        buttonText:'Request Reset Link'
    });

    const {email, buttonText} = values;

    const handleChange = (name) => (event) => {
        setValues({...values, [name]: event.target.value});
    }

    const clickSubmit = event => {
        event.preventDefault();

        if (email.trim()==='')
        {
            toast.error('Email is required.'); 
        }
        else {
            setValues({...values, buttonText:'Submitting...'});
            axios({
                method: 'POST',
                url:`${process.env.REACT_APP_API}/auth/account/password`,
                data: {email}
            })
            .then(response => {
                toast.success(`Email has been sent to ${email}. Follow the instruction to activate your account.`);
                setValues({...values, email:'', buttonText:'Requested'});  
            })
            .catch(error => {
                toast.error(error.response.data.error);
                setValues({...values, buttonText:'Request Reset Link'});
            })
        }
    };

    const ForgotForm = () => (
        <>  
            <h2 className="mb-3 font-weight-normal">Forgot your password?</h2>
            <p>Enter your email below and we'll get you back on track</p>
            <form>
                <div className="form-group mt-4">                    
                    <input onChange={handleChange('email')} value={email} type="email" className="form-control form-control-lg" id="email" aria-describedby="emailHelp" placeholder="email@domain.com" />
                </div>                   
                <button onClick={clickSubmit} type="submit" className="btn btn-primary btn-lg btn-block my-4 float-right rounded-pill">{buttonText}</button>            
                <p>Already have an account ? <Link to="/login" className="font-weight-normal"> Log In</Link></p>
            </form>
        </>
    )

    return (
        <>
            <ToastContainer 
                position="bottom-right"
            />

            <main role="main" className="flex-shrink-0">
                <section className="bg-login">
                    <div className="container mt-md-5 pt-md-5 pb-5">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 text-center">
                                <img className="mb-0" src="/assets/img/logo-color.svg" alt="" width="300" height="150" />
                                <form>
                                    {ForgotForm()}   
                                </form>
                            </div>
                        </div>
                    </div>

                </section>
            </main>

        </>
    )    
}

export default Forgot;
