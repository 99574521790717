import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import PasswordStrengthBar from 'react-password-strength-bar';

const Reset = ({match, history}) => {

    //para manejar el state del registro
    const [values, setValues] = useState({        
        password:'',
        password2:'',
        token:'', 
        buttonText:'Reset Password',
    });

    useEffect(() => {
        //tomamos el token que viene de la url
        let token = match.params.token
        // let {name} = jwt.decode(token);        
        
        if(token) {
            setValues({...values, token});
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const {token, password, password2, buttonText} = values; 

    const handleChange = (name) => (event) => {
        setValues({...values, [name]: event.target.value});
    }       

    const clickSubmit = event => {
        event.preventDefault();

        setValues({ ...values, buttonText:'Submitting'});

        if(password==='' || password2==='')
        {
            toast.error('All fields are required.'); 
            setValues({...values, buttonText:'Reset Password'}); 
        }
        else {
            if (password !== password2){
                toast.error('Passwords not match.'); 
                setValues({...values, buttonText:'Reset Password'}); 
            }
            else {
                axios({
                    method: 'POST',
                    url:`${process.env.REACT_APP_API}/auth/account/change-password`,
                    data: {token, password}
                })
                .then(response => {
                    toast.success('Great! Now you can login with your new password.');
                    setValues({ ...values, buttonText:'Done'})                    
        
                    // if(response.status === 201) {
                    //     //save de response (user,token) localstorage/cookie
                    //     setValues({...values, show:false, buttonText:'Activated!'});
                    //     toast.success('Account activation was successful, please login');
                    //     // history.push('/login');
                    // } else {
                    //     toast.error('An error has occurred. Try later please.');                 
                    // }           
                })
                .catch(error => {
                    toast.error(error.response.data.message);
                    setValues({...values, buttonText:'Reset Password'});           
                    // if(error.response.data.statusCode === 400) {
                    //     toast.error('Token was not found or it is not valid.');
                    // } else {
                    //     toast.error(error.response.data.message); 
                    // }                    
                })        
            }
        }
    };  
    
    const ResetForm = () => (
        <>
            {/* <div className="form-group mt-3">	
                <input className="form-control form-control-lg" id="myPassword" type="password" name="" value="" placeholder="password" />                
            </div>

            <div className="form-group mt-3">	
                <div className="input-group" id="show_hide_password" style={{width:'99%'}}>
                    <input className="form-control form-control-lg" type="password" placeholder="repeat password" />
                    <div className="input-group-append">
                        <a href=""><i className="bi bi-eye" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div> */}

            <div className="form-group mt-3">	
                <div className="input-group" id="show_hide_password" style={{'width':'99%'}}>
                    <input onChange={handleChange('password')} value={password} className="form-control form-control-lg" id="password" type="password"  placeholder="password"/>
                    <div className="input-group-append">
                        <a href="#"><i className="bi bi-eye" aria-hidden="true"></i></a>
                    </div>
                    
                </div>                
            </div>
            
            <div className="form-group mt-3">	
                <div className="input-group" id="show_hide_password" style={{'width':'99%'}}>
                    <input onChange={handleChange('password2')} value={password2} className="form-control form-control-lg" type="password" placeholder="repeat password" />
                    <div className="input-group-append">
                        <a href=""><i className="bi bi-eye" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>

            <PasswordStrengthBar 
                password={password}
                minLength="8" 
                minScore="3"
            />
            
            <button onClick={clickSubmit} type="submit" className="btn btn-primary btn-lg btn-block my-4 float-right rounded-pill">{buttonText}</button>
        </>
    )

    return (
        <>          
        
            <ToastContainer 
                position="bottom-right"
            />          
            
            <main role="main" className="flex-shrink-0">
                <section className="bg-login">
                    <div className="container mt-md-5 pt-md-5 pb-5">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 text-center">
                                <img className="mb-0" src="/assets/img/logo-color.svg" alt="" width="300" height="150" />
                                
					            <p>Please enter your new password</p>
                                <form>
                                    {ResetForm()}  
                                </form>                           
                                <p className="text-center">Already have an account ? <Link to="/login"> Log In</Link></p>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
            
        </>
    )    
}

export default Reset;
