import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import jwt from 'jsonwebtoken';
// import jwt_decode from "jwt-decode";

const Activate = ({match, history}) => {

    //para manejar el state del registro
    const [values, setValues] = useState({       
       
        token:'',
        buttonText:'Change Email',
    });

    useEffect(() => {
        //tomamos el token que viene de la url
        let token = match.params.token
        // let decoded = jwt.decode(token);
        if(token) {
            setValues({...values, token});
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const {token, buttonText} = values;    

    const clickSubmit = event => {
        event.preventDefault();
        // setValues({...values, buttonText:'Activating...'})
        axios({
            method: 'POST',
            url:`${process.env.REACT_APP_API}/auth/account/validate-email`,
            data: {token}
        })
        .then(response => {
            if(response.status === 201) {
                //save de response (user,token) localstorage/cookie
                setValues({...values, show:false, buttonText:'Email changed!'});
                toast.success('The email has been updated, please login');
                // history.push('/login');
            } else {
                toast.error('An error has occurred. Try later please.');                 
            }           
        })
        .catch(error => {
            setValues({...values, buttonText:'Change Email'});           
            // if(error.response.data.statusCode === 400) {
            //     toast.error('Token was not found or it is not valid.');
            // } else {
                toast.error(error.response.data.message); 
            // }
            
        })
    };  
    
    const validateEmailk = () => (
        <>
            <div className="row">
                <h1 className="p-5 text-center">Hey, ready to confirm your new email?</h1>                                 
                <button onClick={clickSubmit} type="submit" className="btn btn-primary btn-lg btn-block my-4 float-right rounded-pill">{buttonText}</button>
                
            </div>
            
        </>
    )

    return (
        <>          
        
            <ToastContainer 
                position="bottom-right"
            />          
            
            <main role="main" className="flex-shrink-0">
                <section className="bg-login">
                    <div className="container mt-md-5 pt-md-5 pb-5">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 text-center">
                                <img className="mb-0" src="/assets/img/logo-color.svg" alt="" width="300" height="150" />

                                {validateEmailk()}                                
                                <p className="text-center">Already have an account ? <Link to="/login"> Log In</Link></p>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
            
        </>
    )    
}

export default Activate;