import { 
	httpApiGet,
	httpApiPost,
	httpApiDelete,
} from "../helpers/axiosPrivate";

export const BUYABLE_PICTURE = 'BUYABLE_PICTURE'
export const BUYABLE_ALBUM = 'BUYABLE_ALBUM'

const initialState = {
	consumerPhoneVerified: false,
	cartLoaded: false,
	orders: [],
	error: false,
	loading: false,
	message: '',
	buyableRemoved: false,
	buyableAdded: false,
	cartBuyables: [],
	removedBuyables:[],
	checkoutOrders:[],
}

const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
const CART_ACTION_FAILURE = 'CART_ACTION_FAILURE'
const ADD_ELEMENT_TO_CART = 'ADD_ELEMENT_TO_CART'
const REMOVE_ELEMENT_FROM_CART = 'REMOVE_ELEMENT_FROM_CART'
const REMOVE_ELEMENTS_FROM_CART = 'REMOVE_ELEMENTS_FROM_CART'
const RESET_CART_MESSAGE = 'RESET_CART_MESSAGE'
const START_LOADING_CART = 'START_LOADING_CART'
const STOP_LOADING_CART = 'STOP_LOADING_CART'
const CHECKOUT_CART = 'CHECKOUT_CART'
const RESET_CHECKOUT_CART = 'RESET_CHECKOUT_CART'

export default function reducer(state = initialState, action){
	switch (action.type) {
		case GET_ORDERS_SUCCESS:
			return {
				...state, 
				orders: action.orders,
				consumerPhoneVerified: action.consumerPhoneVerified,
				cartLoaded:true
			}
		case CART_ACTION_FAILURE:
			return {
				...state, 
				message: action.message,
				error: true
			}
		case ADD_ELEMENT_TO_CART:
			return {
				...state, 
				buyableAdded: true,
				removedBuyables: removeRemovedBuyables(action.buyable,state),
				cartBuyables: [...state.cartBuyables, action.buyable],
				message: action.message
			}
		case REMOVE_ELEMENT_FROM_CART:
			return {
				...state, 
				buyableRemoved: true,
				removedBuyables: addRemovedBuyables(action.buyable,state),
				cartBuyables: state.cartBuyables.filter(buyable => ((buyable.id !== action.buyable.id && buyable.type === action.buyable.type) || buyable.type !== action.buyable.type)),
				message: action.message
			}
		case REMOVE_ELEMENTS_FROM_CART:
			return {
				...state, 
				buyableRemoved: true,
				removedBuyables: addRemovedBuyablesOrder(action.buyables,state),
				cartBuyables: removeCartBuyablesByOrder(action.buyables,state),
				message: action.message
			}
		case RESET_CART_MESSAGE:
			return {
				...state, 
				message: '',
				error: false,
				buyableRemoved: false,
				buyableAdded: false,
			}
		case START_LOADING_CART:
			return {
				...state, 
				loading: true,
			}
		case STOP_LOADING_CART:
			return {
				...state, 
				loading: false,
			}
		case CHECKOUT_CART:
			return {
				...state, 
				checkoutOrders: [...state.checkoutOrders, action.order],
			}
		case RESET_CHECKOUT_CART:
			return {
				...state, 
				checkoutOrders: [],
			}
		default:
			return state;
	}
}

export const getBuyablesAction = () => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_CART,
	});
	try {
		const response = await httpApiGet('user/cart');
		if(response){
			dispatch({
				type: GET_ORDERS_SUCCESS,
				orders: response.orders,
				consumerPhoneVerified: response.consumer.isPhoneVerified,
			});
		}
	} catch (error) {
		dispatch({
			type: CART_ACTION_FAILURE,
			message: error.message,
		});
	} finally{
		dispatch({
			type: STOP_LOADING_CART,
		});
	}
}

export const addBuyableToCartAction = (id, type) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_CART,
	});
	try {
		const response = await httpApiPost(getEndpoint(type),{id});
		if(response){
			dispatch({
				type: ADD_ELEMENT_TO_CART,
				buyable: {id, type},
				message: getBuyableLabel(type) + ' added to cart',
			});
		}
	} catch (error) {
		dispatch({
			type: CART_ACTION_FAILURE,
			message: error.message,
		});
	} finally{
		dispatch({
			type: STOP_LOADING_CART,
		});
	}
}

export const removeBuyableFromCartAction = (id, type) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_CART,
	});
	try {
		const response = await httpApiDelete(getEndpoint(type)+'/'+id);
		if(response){
			dispatch({
				type: GET_ORDERS_SUCCESS,
				orders: response.orders,
				consumerPhoneVerified: response.consumer.isPhoneVerified,
			});
			dispatch({
				type: REMOVE_ELEMENT_FROM_CART,
				buyable: {id, type},
				message: getBuyableLabel(type) + ' removed from cart',
			});
		}
	} catch (error) {
		dispatch({
			type: CART_ACTION_FAILURE,
			message: error.message,
		});
	} finally{
		dispatch({
			type: STOP_LOADING_CART,
		});
	}
}

export const removeOrderFromCartAction = (order) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_CART,
	});
	try {
		const response = await httpApiDelete('user/cart/order/'+order.id);
		if(response){
			dispatch({
				type: GET_ORDERS_SUCCESS,
				orders: response.orders,
				consumerPhoneVerified: response.consumer.isPhoneVerified,
			});
			dispatch({
				type: REMOVE_ELEMENTS_FROM_CART,
				buyables: [
					{ids:order.photos.map(photo => photo.id), type:BUYABLE_PICTURE},
					{ids:order.albums.map(album => album.id), type:BUYABLE_ALBUM},
				],
				message: 'Order removed from cart',
			});
		}
	} catch (error) {
		dispatch({
			type: CART_ACTION_FAILURE,
			message: error.message,
		});
	} finally{
		dispatch({
			type: STOP_LOADING_CART,
		});
	}
}

export const checkoutCartAction = (paymentId,order) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_CART,
	});
	try {
		const data = {
			orderId: order.id,
			currencyId: order.photographer.currency.id,
			price: order.total,
			paymentId,
		}
		const response = await httpApiPost('user/cart/buy',data);
		if(response){
			dispatch({
			 	type: CHECKOUT_CART,
			 	order: order,
			});

			dispatch({
				type: GET_ORDERS_SUCCESS,
				orders: getState().cart.orders.map(cartOrder=>{
					if(cartOrder.id === order.id){
						return {...cartOrder, checkoutDone:true};
					}else{
						return cartOrder;
					}
				}),
				consumerPhoneVerified: getState().cart.consumerPhoneVerified  
			});
		}
	} catch (error) {
		dispatch({
			type: CART_ACTION_FAILURE,
			message: error.message,
		});
		if(error.status === 409){
			dispatch({
				type: START_LOADING_CART,
			});
			try {
				const response = await httpApiGet('user/cart');
				if(response){
					dispatch({
						type: GET_ORDERS_SUCCESS,
						orders: response.orders,
						consumerPhoneVerified: response.consumer.isPhoneVerified,
					});
				}
			} catch (error) {
				dispatch({
					type: CART_ACTION_FAILURE,
					message: error.message,
				});
			} finally{
				dispatch({
					type: STOP_LOADING_CART,
				});
			}
		}
	} finally{
		dispatch({
			type: STOP_LOADING_CART,
		});
	}
}

export const resetCartMessage = () => async (dispatch, getState) => {
	dispatch({
		type: RESET_CART_MESSAGE,
	});
}

export const resetCheckoutCart = () => async (dispatch, getState) => {
	dispatch({
		type: RESET_CHECKOUT_CART,
	});
}

const getBuyableLabel = (type) => {
	switch (type) {
		case BUYABLE_PICTURE:
			return 'Picture';
		case BUYABLE_ALBUM:
			return 'Album';
		default:
			return null;
	}
}

const getEndpoint = (type) => {
	switch (type) {
		case BUYABLE_PICTURE:
			return 'user/cart/photo';
		case BUYABLE_ALBUM:
			return 'user/cart/album';
		default:
			return null;
	}
}

const removeCartBuyablesByOrder = (buyables, state) => {
	return state.cartBuyables
		.filter(buyable => (( !(buyables[0].ids.includes(buyable.id)) && buyable.type === buyables[0].type) || buyable.type !== buyables[0].type))
		.filter(buyable => (( !(buyables[1].ids.includes(buyable.id)) && buyable.type === buyables[1].type) || buyable.type !== buyables[1].type));
}

const addRemovedBuyables = (buyable, state) => {
	let removedBuyablesAux = state.removedBuyables;
	const data = {
		id:buyable.id,
		type:buyable.type
	};
	removedBuyablesAux.push(data);
	return removedBuyablesAux;
}

const removeRemovedBuyables = (buyable, state) => {
	return state.removedBuyables
		.filter(removedBuyable => (( removedBuyable.id !== buyable.id  && buyable.type === removedBuyable.type) || buyable.type !== removedBuyable.type))
}

const addRemovedBuyablesOrder = (buyables, state) => {
	let removedBuyablesAux = state.removedBuyables;
	buyables.forEach(buyableList =>{
		buyableList.ids.forEach(id =>{
			const data ={
				id,
				type:buyableList.type
			}
			removedBuyablesAux.push(data);
		});
	});
	return removedBuyablesAux;
}

export const checkBuyableInCart = (id, type) => (dispatch, getState) => {
	return getState().cart.cartBuyables.filter(buyable => (buyable.id === id && buyable.type === type)).length  > 0;
}

export const checkRemovedBuyable = (id, type) => (dispatch, getState) => {
	return getState().cart.removedBuyables.filter(buyable => (buyable.id === id && buyable.type === type)).length  > 0;
}