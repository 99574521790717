import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { httpApiPost } from '../services/helpers/axiosPrivate';
// import jwt from 'jsonwebtoken';
// import jwt_decode from "jwt-decode";

const Activate = ({match, history}) => {

    //para manejar el state del registro
    const [values, setValues] = useState({       
       
        token:'',
        buttonText:'Activate Account',
    });

    useEffect(() => {
        //tomamos el token que viene de la url
        let token = match.params.token
        // let decoded = jwt.decode(token);
                
        if(token) {
            setValues({...values, token});
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const {token, buttonText} = values;    

    const clickSubmit = async event => {
        event.preventDefault();

        try {
            const response = await httpApiPost('auth/account/activate',{token});
            if(response !== null){
                setValues({...values, show:false, buttonText:'Activated!'});
                toast.success('Account activation was successful, please login');
            }
        } catch (error) {
            setValues({...values, buttonText:'Activate Account'}); 
            // if(error.status === 400) {
            //     toast.error('Token was not found or it is not valid.');
            // } else {
            toast.error(error.message);
            // }
        }
    };  
    
    const activationLink = () => (
        <>
            <div className="row">
                <h1 className="p-5 text-center">Hey, ready to activate your account?</h1>                                 
                <button onClick={clickSubmit} type="submit" className="btn btn-primary btn-lg btn-block my-4 float-right rounded-pill">{buttonText}</button>
                
            </div>
            
        </>
    )

    return (
        <>          
        
            <ToastContainer 
                position="bottom-right"
            />          
            
            <main role="main" className="flex-shrink-0">
                <section className="bg-login">
                    <div className="container mt-md-5 pt-md-5 pb-5">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-12 text-center">
                                <img className="mb-0" src="/assets/img/logo-color.svg" alt="" width="300" height="150" />

                                {activationLink()}                                
                                <p className="text-center">Already have an account ? <Link to="/login"> Log In</Link></p>
                                <p>Need a new link ? <Link to="/account/resend-validation-link"> Request Validation Link</Link></p>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
            
        </>
    )    
}

export default Activate;
