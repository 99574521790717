import React, { useState, useEffect, Fragment } from 'react';
import {Link, withRouter} from 'react-router-dom';
import { logged, getTokenProperty, signout } from '../../auth/helpers';
import {  ROLE_ADMIN } from '../../constants/userRoles';
import { useSelector, useDispatch } from 'react-redux';
import {
	getProfileAction,
} from '../../services/redux/profileRedux';
import ModalPhotographerRegistration from './ModalPhotographerRegistration'

const Header = ({history,relativeHeader}) => {
    const dispatch = useDispatch();
    const profileData = useSelector(store => store.profile.profileData);
    const profileLoaded = useSelector(store => store.profile.profileLoaded);
    const [showModalPhotographerRegistration, setShowModalPhotographerRegistration] = useState(false);

    useEffect(() => {    
        if(!profileLoaded && logged()){
            dispatch(getProfileAction());
        }    
    }, []);

    return(
        <>
            <ModalPhotographerRegistration  
                showModalPhotographerRegistration={showModalPhotographerRegistration}
                closeModal={() => setShowModalPhotographerRegistration(false)}
            />
            <header>
                <div className={`navbar navbar-expand-lg fixed-top ${relativeHeader ? 'position-relative' : '' }`} id="mainNav">
                    <div className="container-fluid">
                        <Link to="/">
                            <span className="navbar-brand js-scroll-trigger" />
                        </Link>

                        <div/>
                        { !logged() &&
                            <div className="photographerRegBtn">
                                {/* <Link className="btn btn-primary btn-sm nav-link js-scroll-trigger py-2 px-3" to={`/register?userType=${ROLE_PHOTOGRAPHER.id}`} style={{color: 'white'}}><i className="bi bi-camera mx-1" style={{color: 'white'}}></i> <span style={{color: 'white'}}> Photographer registration</span></Link> */}
                                <span className="btn btn-primary btn-sm nav-link js-scroll-trigger py-2 px-3"  style={{color: 'white'}} onClick={e => setShowModalPhotographerRegistration(true)}>
                                    <i className="bi bi-camera mx-1" style={{color: 'white'}}></i> <span style={{color: 'white'}}> Photographer registration</span>
                                </span>
                            </div> 
                        }                        
                        <div className="dropdown myAvatar">
                            {logged() && getTokenProperty('role') === ROLE_ADMIN.description && (
                                <Fragment>
                                    <Link to="/admin" className="js-scroll-trigger mr-5"> {profileData.name}</Link>
                                </Fragment>    
                            )}

                            {logged() && getTokenProperty('role') !== ROLE_ADMIN.description && (
                                <Fragment>
                                    <Link to="/dashboard/profile" className="js-scroll-trigger mr-5"> {profileData.name}</Link>
                                </Fragment>    
                            )}
                            <button type="button" className="btn btn-primary btn-round-sm"  style={{'width': '40px', 'height': '40px','borderRadius': '50%','padding': '2px 0px'}} id="dropdownMenuReference" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                                <i className="bi bi-person-circle font-24" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-right animate slideIn"  aria-labelledby="dropdownMenuReference">
                                {!logged() && (
                                    <Fragment>
                                        <Link to="/register" className="dropdown-item"> Register</Link>
                                        <Link to="/login" className="dropdown-item"> Log In</Link>
                                    </Fragment>    
                                )}
                                {logged() && (
                                    <span 
                                        className="dropdown-item" 
                                        style={{'cursor':'pointer'}}
                                        onClick={ () => {
                                            signout( () => {
                                                history.push('/');
                                            });  
                                        }}
                                    >
                                        Sign out
                                    </span>
                                )}  
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default withRouter(Header);