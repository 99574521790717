import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render(){
        const date =new Date().getFullYear();

        return(
            <>
                <footer className="footer mt-auto py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-12 mt-3">
                                <img src="assets/img/logo-color.svg" width="50%" className="logo-footer" alt=""/>
                            </div>
                            <div className="col-md-3 col-6 mt-3">
                                <a href='/about'>
                                    <p className="footerTitle">About</p>
                                </a>
                                <ul className="list-unstyled text-small">
                                    <li className="footerLink">
                                        <a href='/about#about'>
                                            About Findpic
                                        </a>
                                    </li>
                                    {/* <li className="footerLink">
                                        <a href='/about#faq'>
                                            FAQ
                                        </a>
                                    </li> */}
                                    <li className="footerLink">
                                        <a href='/about#contact'>
                                            Contact
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-6 mt-3">
                                <a href='/photographers'>
                                    <p className="footerTitle">Photographers</p>
                                </a>
                                <ul className="list-unstyled text-small">
                                    <li className="footerLink">
                                        <a href='/photographers#how'>
                                            How does it Work?
                                        </a>
                                    </li>
                                    <li className="footerLink">
                                        <a href='/photographers#pricing'>
                                            Pricing
                                        </a>
                                    </li>
                                    <li className="footerLink">
                                        <a href='/photographers#copyright'>
                                            Copyright
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-6 mt-3">
                                <a href='/users'>
                                    <p className="footerTitle">Users</p>
                                </a>
                                <ul className="list-unstyled text-small">
                                    <li className="footerLink">
                                        <a href='/users#how'>
                                            How does it Work?
                                        </a>
                                    </li>
                                    <li className="footerLink">
                                        <a href='/users#pricing'>
                                            Pricing
                                        </a>
                                    </li>
                                    <li className="footerLink">
                                        <a href='/users#copyright'>
                                            Copyright
                                        </a>
                                    </li>
                                    {/* <li className="footerLink">
                                        <a href='/users#privacy'>
                                            Privacy
                                        </a>
                                    </li> */}
                                    {/* <li className="footerLink">
                                        <a href='/users#register'>
                                            Register
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>			
                    </div>
                </footer>
                
                <div className="container-fluid px-0">
                    <div className="legals text-left">
                        <div className="container">
                            © {date} <span className="pr-2">Findpic</span> | 
                            <a className="pl-2" href='/privacyPolicy'>Privacy policy</a> | 
                            <a className="px-2" href="/termsOfService">Terms of service</a> 
                        </div>
                    </div>
                </div>                
            </>
        )
    }
}

export default Footer;