import React from 'react'
import { 
	Modal,
	Button, 
} from 'react-bootstrap';
import TermsOfServiceText from '../common/TermsOfServiceText';

const ModalTermsOfService = (props) => {
	const {
		showModalTermsOfService,
		closeModal,
      acceptTerms,
	} = props

	return (
		<Modal show={showModalTermsOfService} onHide={closeModal} size="lg" scrollable={true} >
			<Modal.Header closeButton>
				<h5 className="modal-title">Terms of Service</h5>
			</Modal.Header>
			<Modal.Body>
			<TermsOfServiceText />
         </Modal.Body>
			<Modal.Footer>
            <Button variant="primary" onClick={props.acceptTerms}>
					Accept
				</Button>
				<Button variant="secondary" onClick={props.closeModal}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalTermsOfService;
