import cookie from 'js-cookie';
import moment from 'moment';
import { PHOTOGRAPHERPERMISSIONS, ADMINPERMISSIONS, CONSUMERPERMISSIONS } from './permissions';

// set in cookie
export const setCookie = (key, value) => {
    if(window != 'undefined'){
        cookie.set(key, value, {
            expires: 1
        });
    }
}

// remove from cookie
export const removeCookie = (key) => {
    if(window != 'undefined'){
        cookie.remove(key, {
            expires: 1
        });
    }
}

// get from cookie such as stored token
// will be useful when we need to make request to server with token
export const getCookie = (key) => {
    if(window != 'undefined'){
        return cookie.get(key);
    }
}

// set in localstorage
export const setLocalStorage = (key, value) => {
    if(window != 'undefined'){
        localStorage.setItem(key, JSON.stringify(value));
    }
}

// remove from localstorage
export const removeLocalStorage = (key) => {
    if(window != 'undefined'){
        localStorage.removeItem(key);
    }
}

// authenticate user by passing data to cookie and localstorage durinisAuthg login
export const authenticate = (response, next) => {
    const token = response.data.accessToken
    setCookie('token', token);
    setCookie('usersTokens', JSON.stringify([token]));
    next();
}

export const addUserToken = (token, next) => {
    let usersTokens = JSON.parse(cookie.get('usersTokens'));
    if(usersTokens.indexOf(token) === -1){
        setCookie('token', token);
        usersTokens.push(token);
        setCookie('usersTokens', JSON.stringify(usersTokens));
    }
    next();
}

export const getUsersTokens = () => {
    return JSON.parse(cookie.get('usersTokens'));
}

export const getTokenProperty = (property) => {
    const token = getToken();
    return tokenData(token)[property];
}

export const setActiveToken = (token, next) => {
    setCookie('token', token);
    next();
}

export const getTokenPropertyByToken = (token, property) => {
    return tokenData(token)[property];
}

export const getToken = () => {
    if(window !== 'undefined'){
        return getCookie('token');
    }
}

export const logged = () =>{
    const token = getToken();
  
    if (!token || !isValidToken(token)) {
      return false;
    }
    return true;
}

export const tokenData = (token) => {
    return JSON.parse(atob(token.split('.')[1]));
}
  
export const isValidToken = (token) => {
    return moment().isBefore(moment(new Date(tokenData(token).exp * 1000)));
}

export const hasPermission = (permissionExpected) => {
    let ret = false;
    const token = getToken();
  
    if (token === null) {
      return false;
    }
  
    tokenData(token).CLAIM_TOKEN.forEach(t => {
        if (t.authority === permissionExpected) {
            ret = true;
            return;
        }
    });
  
    return ret;
}

export const hasPermissionHardc = (permissionExpected) => {
    const role = getTokenProperty('role');
    let match = false;
    if(role){
        if(role === 'ROLE_ADMIN'){
            ADMINPERMISSIONS.forEach(t => {
                if (t === permissionExpected) {
                    match = true;
                }
            });
        }else if(role === 'ROLE_CONSUMER'){
            CONSUMERPERMISSIONS.forEach(t => {
                if (t === permissionExpected) {
                    match = true;
                }
            });
        }else{
            PHOTOGRAPHERPERMISSIONS.forEach(t => {
                if (t === permissionExpected) {
                    match = true;
                    return;
                }
            });
        }
    }
    return match;
}
  
export const signout = next => {
    let tokens = getUsersTokens();
    const currentToken = getToken();
    removeCookie('token');

    if(tokens.length >1){
        const index = tokens.indexOf(currentToken);
        if (index > -1) {
            tokens.splice(index, 1);
        }
        setCookie('usersTokens', JSON.stringify(tokens));
        setCookie('token', tokens[0]);
    }
    if(next){
        next();
    }
};