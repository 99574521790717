import { httpApiGet, httpApiPost, httpApiDelete } from "../helpers/axiosPrivate";

const initialState = {
	profileLoaded:false,
	showPhoneValidationModal:false,
	loading: false,
	error: false,
	message: '',
	userProfile: null,
	profileData:{
		name: '',
		surname: '',
		email: '',
		countryCode: '',
		cca2: '',
		phone: '',
		isPhoneVerified:false
	},
	pricing:{
        currency: null,
        strategy: false,
        ranges: [],
		price:''
    },
	social: {
		facebook: false,
		instagram: false,
		twitter: false,
		google: false,
	},  
	payments: {
		paypal: false,
		zelle: false,
		bit: false,
		paybox: false,
		cash: false,
		venmo: false,
	}
}

const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
const SET_PROFILE_DATA_SUCCESS = 'SET_PROFILE_DATA_SUCCESS'
const SET_PRICING_DATA_SUCCESS = 'SET_PRICING_DATA_SUCCESS'
const SET_PAYMENTS_DATA_SUCCESS = 'SET_PAYMENTS_DATA_SUCCESS'
const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
const PROFILE_ACTION_FAILURE = 'PROFILE_ACTION_FAILURE'
const START_LOADING_PROFILE = 'START_LOADING_PROFILE'
const STOP_LOADING_PROFILE = 'STOP_LOADING_PROFILE'
const RESET_PROFILE_MESSAGE = 'RESET_PROFILE_MESSAGE'
const RESET_USER_PROFILE = 'RESET_USER_PROFILE'
const SHOW_MESSAGE_PROFILE = 'SHOW_MESSAGE_PROFILE'
const SHOW_PHONE_VALIDATION_MODAL_PROFILE = 'SHOW_PHONE_VALIDATION_MODAL_PROFILE'
const HIDE_PHONE_VALIDATION_MODAL_PROFILE = 'HIDE_PHONE_VALIDATION_MODAL_PROFILE'

export default function reducer(state = initialState, action){
	switch (action.type) {
		case GET_PROFILE_SUCCESS:
			return {
				...state, 
				profileLoaded:true,
				...action.payload
			}
		case SET_PROFILE_DATA_SUCCESS:
			return {
				...state, 
				profileData:{
					...state.profileData,
					...action.payload
				}
			}
		case SET_PRICING_DATA_SUCCESS:
			return {
				...state, 
				pricing:{
					...state.pricing,
					...action.payload
				}
			}
		case SET_PAYMENTS_DATA_SUCCESS:
			return {
				...state, 
				payments:{
					...state.payments,
					[action.payment]:action.value
				}
			}
		case GET_USER_PROFILE_SUCCESS:
			return {
				...state, 
				userProfile:action.payload
			}
		case PROFILE_ACTION_FAILURE:
			return {
				...state, 
				message: action.message,
				error: true
			}
		case START_LOADING_PROFILE:
			return {
				...state, 
				loading: true,
			}
		case STOP_LOADING_PROFILE:
			return {
				...state, 
				loading: false,
			}
		case SHOW_MESSAGE_PROFILE:
			return {
				...state, 
				message: action.message,
				error: action.payload
			}
		case RESET_PROFILE_MESSAGE:
			return {
				...state, 
				message: '',
				error: false
			}
		case RESET_USER_PROFILE:
			return {
				...state, 
				userProfile: null
			}
		case SHOW_PHONE_VALIDATION_MODAL_PROFILE:
			return {
				...state, 
				showPhoneValidationModal: true
			}
		case HIDE_PHONE_VALIDATION_MODAL_PROFILE:
			return {
				...state, 
				showPhoneValidationModal: false
			}
		default:
			return state;
	}
}

export const getProfileAction = () => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});
	try {
		const data = await httpApiGet('user/profile');
		if(data){
			dispatch({
				type: GET_PROFILE_SUCCESS,
				payload: data,
			});
		}
	} catch (error) {
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}

export const getUserProfileAction = (userId) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});
	try {
		const data = await httpApiGet('user/profile',{userId});
		if(data){
			dispatch({
				type: GET_USER_PROFILE_SUCCESS,
				payload: data,
			});
		}
	} catch (error) {
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}

export const saveAccountProfileAction = (data) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});
	try {
		const response = await httpApiPost('user/profile/data',data);
		if(response){
			if(
				// getState().profile.profileData.phone !== data.phone ||
				// getState().profile.profileData.countryCode !== data.countryCode ||
				// !getState().profile.profileData.isPhoneVerified || 
				response.sentSMS
			){
				dispatch({
					type: SHOW_PHONE_VALIDATION_MODAL_PROFILE,
				});
			}
			dispatch({
				type: SET_PROFILE_DATA_SUCCESS,
				payload: data
			});
			dispatch({
				type: SHOW_MESSAGE_PROFILE,
				message: response.message,
				payload:false
			});
		}
	} catch (error) {
		if(error.status === 501){
			dispatch({
				type: SET_PROFILE_DATA_SUCCESS,
				payload: {isPhoneVerified:false}
			});
		}
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}

export const savePricingAction = (data) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});
	try {
		const auxData = {
			...data,
			currency:data.currency.id
		}
		const response = await httpApiPost('user/pricing',auxData);
		if(response){
			dispatch({
				type: SET_PRICING_DATA_SUCCESS,
				payload: data
			});
			dispatch({
				type: SHOW_MESSAGE_PROFILE,
				message: response.message,
				payload:false
			});
		}
	} catch (error) {
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}

export const savePaymentAction = (paymentMethod,functionState) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});
	try {
		let response;
		if(functionState){
			response = await httpApiPost('user/payments/' + paymentMethod);
		}else{
			response = await httpApiDelete('user/payments/' + paymentMethod);
		}
		if(response){
			dispatch({
				type: SET_PAYMENTS_DATA_SUCCESS,
				payment: paymentMethod,
				value: functionState
			});
			dispatch({
				type: SHOW_MESSAGE_PROFILE,
				message: response.message,
				payload:false
			});
		}
	} catch (error) {
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}

export const sendPhoneVerificationCodeAction = (data) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});
	try {
		const response = await httpApiPost('user/profile/validate-phone',data);
		if(response){
			dispatch({
				type: HIDE_PHONE_VALIDATION_MODAL_PROFILE,
			});
			dispatch({
				type: SET_PROFILE_DATA_SUCCESS,
				payload: {isPhoneVerified:true}
			});
			dispatch({
				type: SHOW_MESSAGE_PROFILE,
				message: response.message,
				payload: false
			});
		}
	} catch (error) {
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}

export const saveWatermarkProfileAction = (data) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_PROFILE,
	});
	try {
		const response = await httpApiPost('user/watermark',data);
		if(response){
			dispatch({
				type: SHOW_MESSAGE_PROFILE,
				message: response.message,
				payload:false
			});
		}
	} catch (error) {
		dispatch({
			type: PROFILE_ACTION_FAILURE,
			message: error.message,
		});
	}finally{
		dispatch({
			type: STOP_LOADING_PROFILE,
		});
	}
}

export const resetUserProfileAction = () =>  (dispatch, getState) => {
	dispatch({
		type: RESET_USER_PROFILE,
	});
}

export const resetProfileErrorMessage = () => async (dispatch, getState) => {
	dispatch({
		type: RESET_PROFILE_MESSAGE,
	});
}
