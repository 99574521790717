export const VIEW_HOME_CONSUMER = 'VIEW_HOME_CONSUMER';
export const VIEW_HOME_PHOTOGRAPHER = 'VIEW_HOME_PHOTOGRAPHER';
export const VIEW_HOME_ADMIN = 'VIEW_HOME_ADMIN';
export const VIEW_PROFILE = 'VIEW_PROFILE';
export const VIEW_PROFILE_ADMIN = 'VIEW_PROFILE_ADMIN';
export const VIEW_ALBUMS = 'VIEW_ALBUMS';
export const VIEW_ALBUM = 'VIEW_ALBUM';
export const VIEW_UPLOAD_PICTURES_BUTTON = 'VIEW_UPLOAD_PICTURES_BUTTON';
export const VIEW_CONSUMERS_PHOTOGRAPHER = 'VIEW_CONSUMERS_PHOTOGRAPHER';
export const VIEW_CONSUMERS_ADMIN = 'VIEW_CONSUMERS_ADMIN';
export const VIEW_PHOTOGRAPHERS_CONSUMER = 'VIEW_PHOTOGRAPHERS_CONSUMER';
export const VIEW_PHOTOGRAPHERS_ADMIN = 'VIEW_PHOTOGRAPHERS_ADMIN';
export const VIEW_ORDERS_CONSUMER = 'VIEW_ORDERS_CONSUMER';
export const VIEW_ORDERS_ADMIN = 'VIEW_ORDERS_ADMIN';
export const VIEW_ORDERS = 'VIEW_ORDERS';
export const VIEW_ACCOUNTING_CONSUMER = 'VIEW_ACCOUNTING_CONSUMER';
export const VIEW_ACCOUNTING_PHOTOGRAPHER = 'VIEW_ACCOUNTING_PHOTOGRAPHER';
export const VIEW_ACCOUNTING_ADMIN = 'VIEW_ACCOUNTING_ADMIN';
export const VIEW_ADD_ADMIN = 'VIEW_ADD_ADMIN';
export const VIEW_MY_FILES = 'VIEW_MY_FILES';
export const VIEW_CART = 'VIEW_CART';
export const VIEW_CHECKOUT = 'VIEW_CHECKOUT';
export const VIEW_ALBUMS_OF_ME = 'VIEW_ALBUMS_OF_ME';
export const VIEW_PICTURES_OF_ME = 'VIEW_PICTURES_OF_ME';
export const VIEW_PURCHASED_PICTURES = 'VIEW_PURCHASED_PICTURES';
export const VIEW_EDIT_PICTURES = 'VIEW_EDIT_PICTURES';
export const VIEW_DELETED_PICTURES_CONSUMER = 'VIEW_DELETED_PICTURES_CONSUMER';
export const VIEW_DELETED_PICTURES_PHOTOGRAPHER = 'VIEW_DELETED_PICTURES_PHOTOGRAPHER';
export const VIEW_SOCIAL_MEDIA_SETTINGS = 'VIEW_SOCIAL_MEDIA_SETTINGS';
export const VIEW_WATERMARK_SETTINGS = 'VIEW_WATERMARK_SETTINGS';
export const VIEW_PAYMENTS_SETTINGS = 'VIEW_PAYMENTS_SETTINGS';
export const VIEW_PRICING_SETTINGS = 'VIEW_PRICING_SETTINGS';
export const VIEW_IMAGE_RECOGNITION_SETTINGS = 'VIEW_IMAGE_RECOGNITION_SETTINGS';

export const CONSUMERPERMISSIONS = [
    VIEW_HOME_CONSUMER,
    VIEW_PROFILE,
    VIEW_PHOTOGRAPHERS_CONSUMER,
    VIEW_ORDERS_CONSUMER,
    VIEW_ACCOUNTING_CONSUMER,
    VIEW_MY_FILES,
    VIEW_CART,
    VIEW_CHECKOUT,
    VIEW_ALBUMS_OF_ME,
    VIEW_PICTURES_OF_ME,
    VIEW_PURCHASED_PICTURES,
    VIEW_EDIT_PICTURES,
    VIEW_DELETED_PICTURES_CONSUMER,
    VIEW_IMAGE_RECOGNITION_SETTINGS,
];

export const PHOTOGRAPHERPERMISSIONS = [
    VIEW_HOME_PHOTOGRAPHER,
    VIEW_PROFILE,
    VIEW_ALBUMS,
    VIEW_ALBUM,
    VIEW_UPLOAD_PICTURES_BUTTON,
    VIEW_CONSUMERS_PHOTOGRAPHER,
    VIEW_ORDERS,
    VIEW_ACCOUNTING_PHOTOGRAPHER,
    VIEW_DELETED_PICTURES_PHOTOGRAPHER,
    VIEW_SOCIAL_MEDIA_SETTINGS,
    VIEW_WATERMARK_SETTINGS,
    VIEW_PRICING_SETTINGS,
    VIEW_PAYMENTS_SETTINGS,
];

export const ADMINPERMISSIONS = [
    VIEW_HOME_ADMIN,
    VIEW_PROFILE_ADMIN,
    VIEW_CONSUMERS_ADMIN,
    VIEW_PHOTOGRAPHERS_ADMIN,
    VIEW_ORDERS_ADMIN,
    VIEW_ACCOUNTING_ADMIN,
    VIEW_ADD_ADMIN,
];