import { 
	httpApiGet, 
	httpApiGetPaginated, 
	httpApiUploadFile 
} from "../helpers/axiosPrivate";

const initialState = {
	landingRecognitionPicture:null,
	landingPictures:null,
	albumsOfMe: null,
	myPictures: null,
	loadingMyPictures: false,
	error: false,
	message: '',
	showLandingModal: false,
	recognitionPicture: null,
	recognitionPictureNotTaken: false,
}

const GET_LANDING_IMAGES_SUCCESS = 'GET_LANDING_IMAGES_SUCCESS'
const GET_MY_ALBUMS_SUCCESS = 'GET_MY_ALBUMS_SUCCESS'
const GET_MY_PICTURES_SUCCESS = 'GET_MY_PICTURES_SUCCESS'
const IMAGE_RECOGNITION_ACTION_FAILURE = 'IMAGE_RECOGNITION_ACTION_FAILURE'
const RESET_IMAGE_RECOGNITION_MESSAGE = 'RESET_IMAGE_RECOGNITION_MESSAGE'
const HIDE_IMAGE_RECOGNITION_LANDING_MODAL = 'HIDE_IMAGE_RECOGNITION_LANDING_MODAL'
const SET_IMAGE_RECOGNITION_PICTURE = 'SET_IMAGE_RECOGNITION_PICTURE'
const GET_IMAGE_RECOGNITION_PICTURE = 'GET_IMAGE_RECOGNITION_PICTURE'
const IMAGE_RECOGNITION_PICTURE_NOT_TAKEN = 'IMAGE_RECOGNITION_PICTURE_NOT_TAKEN'
const MANAGE_LOADING_MY_PICTURES = 'MANAGE_LOADING_MY_PICTURES'
const auxDataPagination ={
	page:0,
	size:50,
	sort:'createdAt',
	dir:'DESC',
}

export default function reducer(state = initialState, action){
	switch (action.type) {
		case GET_LANDING_IMAGES_SUCCESS:
			return {
				...state, 
				landingRecognitionPicture: action.landingRecognitionPicture,
				landingPictures: action.payload,
				message: '',
				error: false,
				showLandingModal: true
			}
		case GET_MY_ALBUMS_SUCCESS:
			return {
				...state, 
				albumsOfMe: action.payload,
				message: '',
				error: false,
				recognitionPictureNotTaken: false
			}
		case GET_MY_PICTURES_SUCCESS:
			return {
				...state, 
				myPictures: action.payload,
				message: '',
				error: false,
			}
		case IMAGE_RECOGNITION_ACTION_FAILURE:
			return {
				...state, 
				message: action.message,
				error: true
			}
		case HIDE_IMAGE_RECOGNITION_LANDING_MODAL:
			return {
				...state, 
				showLandingModal: false
			}
		case RESET_IMAGE_RECOGNITION_MESSAGE:
			return {
				...state, 
				message: '',
				error: false
			}
		case SET_IMAGE_RECOGNITION_PICTURE:
			return {
				...state, 
				recognitionPicture: action.payload,
				message: '',
				error: false,
				recognitionPictureNotTaken: false
			}
		case GET_IMAGE_RECOGNITION_PICTURE:
			return {
				...state, 
				recognitionPicture: action.payload,
				message: '',
				error: false
			}
		case IMAGE_RECOGNITION_PICTURE_NOT_TAKEN:
			return {
				...state, 
				recognitionPictureNotTaken: true,
			}
		case MANAGE_LOADING_MY_PICTURES:
			return {
				...state, 
				loadingMyPictures: action.payload,
			}
		default:
			return state;
	}
}

export const getLandingPicturesAction = (image) => async (dispatch, getState) => {
	try {
		const images = await httpApiUploadFile('findpic', image);
		if(images !== null){
			dispatch({
				type: GET_LANDING_IMAGES_SUCCESS,
				payload: images,
				landingRecognitionPicture: image.get('photo'),
			});
		}
	} catch (error) {
		dispatch({
			type: IMAGE_RECOGNITION_ACTION_FAILURE,
			message: error.message,
		});
	}
}

export const getMyAlbumsAction = (data=auxDataPagination) => async (dispatch, getState) => {
	dispatch({
		type: MANAGE_LOADING_MY_PICTURES,
		payload: true,
	});
	try {
		const albums = await httpApiGetPaginated('user/findpic', data);
		if(albums){
			dispatch({
				type: GET_MY_ALBUMS_SUCCESS,
				payload: albums,
			});
		}
	} catch (error) {
		if(error.status === 404){
			dispatch({
				type: IMAGE_RECOGNITION_PICTURE_NOT_TAKEN,
			});
		}
		dispatch({
			type: IMAGE_RECOGNITION_ACTION_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: MANAGE_LOADING_MY_PICTURES,
			payload: false,
		});
	}
}

export const getImageRecognitionPictureAction = () => async (dispatch, getState) => {
	try {
		const image = await httpApiGet('user/recognition/photo');
		if(image){
			dispatch({
				type: GET_IMAGE_RECOGNITION_PICTURE,
				payload: image,
			});
		}
	} catch (error) {
		dispatch({
			type: IMAGE_RECOGNITION_ACTION_FAILURE,
			message: error.message,
		});
	}
}

export const resetImageRecognitionErrorMessage = () => async (dispatch, getState) => {
	dispatch({
		type: RESET_IMAGE_RECOGNITION_MESSAGE,
	});
}

export const hideLandingImageRecognitionModal = () => async (dispatch, getState) => {
	dispatch({
		type: HIDE_IMAGE_RECOGNITION_LANDING_MODAL,
	});
}

export const setRecognitionPicture = (picture) => async (dispatch, getState) => {
	dispatch({
		type: SET_IMAGE_RECOGNITION_PICTURE,
		payload: picture
	});
}