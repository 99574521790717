import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Camera from "../utils/Camera";
import { Modal } from "react-bootstrap";
import LoadingOverlay from "../utils/LoadingOverlay";
import PicturesContainerMasonry from "../utils/PicturesContainerMasonry";
import { useSelector } from "react-redux";
import {
  getLandingPicturesAction,
  resetImageRecognitionErrorMessage,
  hideLandingImageRecognitionModal,
} from "../../services/redux/imageRecognitionRedux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { connect } from "react-redux";
import ModalPhotographerRegistration from "./ModalPhotographerRegistration";
import TermsOfService from "./TermsOfService";
import TermsOfServiceText from "../common/TermsOfServiceText";
import ModalTermsOfService from "./ModalTermsOfService";
import ModalFoundPhotos from "./ModalFoundPhotos";

const Home = (props) => {
  const [showTakeSelfie, setShowTakeSelfie] = useState(false);
  const [outerTakePicture, setOuterTakePicture] = useState(false);
  const [showModalTermsOfService, setShowModalTermsOfService] = useState(false);
  const [acceptedTermsOfService, setAcceptedTermsOfService] = useState(false);
  const [selfie, setSelfie] = useState(null);
  const [modalFoundPhotosShow, setModalFoundPhotosShow] = useState(false);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);
  const myPictures = useSelector(
    (store) => store.imageRecognition.landingPictures
  );
  const errorRedux = useSelector((store) => store.imageRecognition.error);
  const errorMessageRedux = useSelector(
    (store) => store.imageRecognition.message
  );
  const showLandingModal = useSelector(
    (store) => store.imageRecognition.showLandingModal
  );
  const [
    showModalPhotographerRegistration,
    setShowModalPhotographerRegistration,
  ] = useState(false);

  useEffect(() => {
    if (errorRedux) {
      toast.error(errorMessageRedux);
      props.resetImageRecognitionErrorMessage();
    }
    if (showLandingModal) {
      setModalFoundPhotosShow(true);
      props.hideLandingImageRecognitionModal();
    }
  }, [errorRedux, showLandingModal, errorMessageRedux]);

  const uploadselfie = async () => {
    try {
      setIsLoadingOverlay(true);

      const formData = new FormData();
      formData.append("photo", selfie);
      await props.getLandingPicturesAction(formData);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoadingOverlay(false);
    }
  };

  // const ModalFoundPhotos = () => {
  //   return (
  //     <Modal
  //       show={modalFoundPhotosShow}
  //       onHide={(e) => setModalFoundPhotosShow(false)}
  //       keyboard={false}
  //       dialogClassName="modal-70w"
  //     >
  //       <Modal.Header closeButton className="photobooth-header" />
  //       <Modal.Body className="p-0 mt-5">
  //         <>
  //           <PicturesContainerMasonry pictures={myPictures} />
  //           <div className="row p-3">
  //             <div className="col-12">
  //               <h3 className="mb-3">Hi,</h3>
  //               <p>We found {myPictures && myPictures.length} photos of you!</p>
  //               <p>To see more pictures of you we invite you to:</p>
  //             </div>
  //           </div>
  //         </>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Link to={`/register`} className="btn btn-secondary btn-block">
  //           Register
  //         </Link>
  //         <Link to="/login" className="btn btn-primary btn-block">
  //           Log In
  //         </Link>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };

  return (
    <>
      <ToastContainer position="bottom-right" />

      <main role="main" className="flex-shrink-0">
        {isLoadingOverlay && <LoadingOverlay />}
        <ModalFoundPhotos
        showModalFoundPhotos={modalFoundPhotosShow}
        closeModal={() => setModalFoundPhotosShow(false)}
        myPictures={myPictures}
        />
        <ModalTermsOfService
          showModalTermsOfService={!acceptedTermsOfService && showModalTermsOfService}
          closeModal={() => setShowModalTermsOfService(false)}
          acceptTerms={() => {
            setShowModalTermsOfService(false)
            setAcceptedTermsOfService(true);
          }}
        />
        <ModalPhotographerRegistration
          showModalPhotographerRegistration={showModalPhotographerRegistration}
          closeModal={() => setShowModalPhotographerRegistration(false)}
        />
        <section id="home">
          <div className="container-fluid px-0">
            <div className="bg-home-container">
              <div className="bg-main">
                <div className="row">
                  <div className="col-lg-5 col-12 home-text">
                    <h1 className="mb-3 h1-large font-weight-normal ls-5">
                      Get started
                    </h1>
                    <h1 className="font-weight-lighter lh-1">
                      Take a selfie and
                      <br />
                      <strong>find your pictures.</strong>
                    </h1>
                    <button
                      className="btn btn-primary mt-4 mr-3"
                      onClick={(e) => {
                        setShowModalTermsOfService(true);
                        setOuterTakePicture(true);
                      }}
                    >
                      <i className="bi bi-person-bounding-box mr-2"></i>
                      Take a selfie
                    </button>
                    <Link
                      to={`/register`}
                      className="btn btn-secondary mt-4 mr-3"
                    >
                      <i className="bi bi-person mr-2"></i>Register
                    </Link>
                    <br />
                    <br />
                    <br />
                    {/* <Link to={`/register?userType=${ROLE_PHOTOGRAPHER.id}`} className="h4 pt-4" >Looking to register as a photographer?</Link> */}
                    <span
                      className="h4 pt-4 text-link"
                      onClick={(e) =>
                        setShowModalPhotographerRegistration(true)
                      }
                    >
                      Looking to register as a photographer?
                    </span>
                  </div>
                  <div className="col-lg-7 col-12">
                    <div className="photo-booth-container">
                      <div className="bg-bubbles"></div>
                      <div className="bg-camera">
                        {showTakeSelfie && (
                          <div className="takeSelfie">
                            <button
                              className="btn btn-link dismiss-selfie"
                              onClick={(e) => {
                                setShowTakeSelfie(false);
                              }}
                            >
                              <i className="bi bi-x-circle"></i>
                            </button>
                          </div>
                        )}
                        {acceptedTermsOfService && (<Camera
                          outerTakePicture={outerTakePicture}
                          setOuterTakePicture={setOuterTakePicture}
                          webcamClassname={"homeWebcamStyle"}
                          pictureClassname={"homePictureWebcamStyle"}
                          getPictureParent={setSelfie}
                          actionPictureParent={uploadselfie}
                        />)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="discover">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="card border-0 shadow rounded-xl"
                  style={{
                    backgroundImage: "url(assets/img/bg-signup.jpg)",
                    backgroundRepeat: "no-Repeat",
                    minHeight: "500px",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="card-body card-facerecognition">
                    <h1 className="mb-3 mt-5 lh-1">
                      Find those memories that
                      <br />
                      otherwise would be lost forever
                    </h1>
                    <h3 className="card-title mt-5 mb-0">
                      Using AI software to lookup those special pictures
                    </h3>
                    <p className="card-text">
                      No registration fees, create an account and start enjoying.
                    </p>
                    <Link to={`/register`} className="btn btn-dark mt-4">
                      Register
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="join">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="card border-0 shadow rounded-xl"
                  style={{
                    backgroundImage: "url(assets/img/bg-join.jpg)",
                    backgroundRepeat: "no-Repeat",
                    minHeight: "500px",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="card-body card-facerecognition text-right mr-4">
                    <h1 className="mb-3 mt-5 lh-1">
                      Free your time to do
                      <br />
                      only what you love!
                    </h1>
                    <h3 className="card-title mt-5 lh-3">
                      Take the photos and upload them
                    </h3>
                    <h3 className="card-title mt-4 lh-3">
                    The clients? Let us do the hard work for you!
                    </h3>
                    <button
                      className="btn btn-dark mt-5"
                      onClick={(e) =>
                        setShowModalPhotographerRegistration(true)
                      }
                    >
                      Photographer registration
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLandingPicturesAction: (picture) =>
      dispatch(getLandingPicturesAction(picture)),
    resetImageRecognitionErrorMessage: () =>
      dispatch(resetImageRecognitionErrorMessage()),
    hideLandingImageRecognitionModal: () =>
      dispatch(hideLandingImageRecognitionModal()),
  };
};

export default connect(null, mapDispatchToProps)(Home);
