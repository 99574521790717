export const ROLE_PHOTOGRAPHER = {
	id: 1,
	description:'ROLE_PHOTOGRAPHER',
	descriptionUser:'Photographer',
};
export const ROLE_CONSUMER = {
	id: 2,
	description:'ROLE_CONSUMER',
	descriptionUser:'Consumer',
};
export const ROLE_ADMIN = {
	id: 3,
	description:'ROLE_ADMIN',
	descriptionUser:'Admin',
};


export const getSignUpUserType = (userType) =>{
	switch (parseInt(userType)) {
		case ROLE_CONSUMER.id:
			return ROLE_CONSUMER;
		case ROLE_PHOTOGRAPHER.id:
			return ROLE_PHOTOGRAPHER;
		default:
			return ROLE_CONSUMER;
	}
}
export const switchSignUpUserType = (userType) =>{
	switch (parseInt(userType)) {
		case ROLE_CONSUMER.id:
			return ROLE_PHOTOGRAPHER;
		case ROLE_PHOTOGRAPHER.id:
			return ROLE_CONSUMER;
		default:
			return ROLE_CONSUMER;
	}
}