import React from 'react'
import { 
	Modal,
	Button, 
} from 'react-bootstrap';

const ModalPhotographerRegistration = (props) => {
	const {
		showModalPhotographerRegistration,
		closeModal,
	} = props

	return (
		<Modal show={showModalPhotographerRegistration} onHide={closeModal}>
			<Modal.Header closeButton>
				<h5 className="modal-title">Photographer Registration</h5>
			</Modal.Header>
			<Modal.Body>
				<p>
					Thank you for your interest in registering to Findpic as a photographer. 
					<br/>
					Unfortunately at the moment we are not ready to take on new photographers.
				</p>
				<br/>
				<p>If you need more information please send an email to: <span className="text-link">info@findpic.co</span></p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={props.closeModal}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalPhotographerRegistration;
